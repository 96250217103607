<template>
  <page-loading v-if="state.pageLoading"></page-loading>
  <div v-if="!state.pageLoading" class="page-container-new">
    <page-header
      title="콘텐츠 수정"
      :back-button="true"
      :is-back-btn-action="true"
      @backBtnAction="actions.goToContents()"
    >
      <template #headerRightSide>
        <button-basic
          text="콘텐츠 목록 보기"
          padding="8px 16px"
          @action="actions.goToContents()"
        ></button-basic>
      </template>
    </page-header>

    <content-form
      :content="state.content"
      :resource-id="state.contentResourceId"
      @cancel-content="actions.goToContents()"
    ></content-form>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { computed, onBeforeMount, onBeforeUnmount, reactive } from "vue";
import { useRouter } from "vue-router";
import PageLoading from "../../../components/console/loadings/PageLoading";
import ContentForm from "../../../components/console/forms/ContentForm";
import PageHeader from "../../../components/console/headers/PageHeader";
import ButtonBasic from "../../../components/console/buttons/ButtonBasic";

export default {
  name: "ContentEdit",
  components: {
    ButtonBasic,
    PageHeader,
    ContentForm,
    PageLoading,
  },
  props: {
    contentResourceId: {
      type: String,
    },
  },
  setup(props) {
    const store = useStore();
    const router = useRouter();

    const state = reactive({
      content: computed(() => {
        return store.getters["contents/content"];
      }),
      pageLoading: false,
      prevFeaturedImage: "",
      featuredImageFile: null,
      contentResourceId: "",
    });

    onBeforeMount(() => {
      state.contentResourceId = props.contentResourceId;
      resetContent();
      setContent();
    });

    const setContent = () => {
      state.pageLoading = true;
      store
        .dispatch("contents/getContent", {
          contentResourceId: state.contentResourceId,
        })
        .then(() => {
          state.pageLoading = false;
        });
    };
    const resetContent = () => {
      store.commit("contents/CONTENT_RESET");
    };

    onBeforeUnmount(() => {
      resetContent();
    });

    const actions = {
      goToContents: () => {
        router.push({ name: "console.contents" });
      },
    };

    return {
      state,
      actions,
    };
  },
};
</script>
